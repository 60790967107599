@use "/src/styles/constants";

.bulletsWrapper {
  display: flex;
  padding: 18px;
  padding-right: 73px;
  gap: 10px;

  @media (max-width: constants.$tablet) {
    padding-right: 50px;
  }

  @media (max-width: constants.$mobile) {
    padding-right: 30px;
  }
}

.bullet {
  display: inline-block;
  height: 3px;
  width: 48px;
  background-color: white;
  opacity: 0.7;

  &_active {
    opacity: 1;
  }

  @media (max-width: constants.$tablet-l) {
    width: 43px;
  }

  @media (max-width: constants.$tablet) {
    width: 38px;
  }

  @media (max-width: constants.$mobile) {
    width: 30px;
  }
}

.imageWrapper {
  width: 100vw;
  height: 600px;
  position: relative;

  @media (max-width: constants.$laptop) {
    height: 500px;
  }

  @media (max-width: constants.$tablet-l) {
    height: 430px;
  }

  @media (max-width: constants.$mobile) {
    height: 390px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
