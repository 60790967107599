@use "../../styles/constants";

.container {
  width: constants.$laptop;
  max-width: 100%;
  height: 100%;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: constants.$laptop) {
    width: constants.$tablet-l;
    padding: 0 50px;
  }

  @media (max-width: constants.$tablet-l) {
    width: constants.$tablet;
    padding: 0 30px;
  }

  @media (max-width: constants.$tablet) {
    width: constants.$mobile;
    padding: 0 20px;
  }

  @media (max-width: constants.$mobile) {
    width: 100%;
    padding: 0 15px;
  }
}
