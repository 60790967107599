@use "/src/styles/constants";

.brand {
  display: flex;
  align-items: center;
  color: #101014;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  position: relative;
  z-index: 2;
  transition: color 0.5s ease;

  span {
    color: #787e83;
  }

  &_secondary {
    transition: color 0.1s ease;
    color: white;
  }
}

.logo {
  position: relative;
  width: 39px;
  height: 45px;
  margin: 0 8px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &_mobileOnly {
    display: none;

    @media (max-width: constants.$tablet) {
      display: initial;
    }
  }
}