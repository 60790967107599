@use "/src/styles/constants";

.section {
  background-color: white;
  padding: 60px 0;

  @media (max-width: constants.$tablet) {
    padding: 30px 0;
  }

  &_gray {
    background-color: #f5f5f5;
  }
}
