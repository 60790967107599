@use "/src/styles/constants";

.footer {
  background-color: white;
}

.content {
  border-top: 1px solid #323232;
  padding: 40px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: constants.$tablet) {
    padding: 30px 0;
  }
}

.menuSection {
  display: flex;
  align-items: flex-start;

  @media (max-width: constants.$tablet) {
    flex-direction: column;
    margin: 0 0 30px;
  }
}

.sectionsWrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: constants.$tablet) {
    flex-direction: column;
  }
}

.brand {
  margin: 0 140px 0 0;

  @media (max-width: constants.$tablet) {
    margin: 0 0 15px;
  }
}

.menu {
  margin: 0 20px 0 0;

  @media (max-width: constants.$tablet) {
    margin: 0;
  }

  &__item {
    margin: 0 0 10px;
    cursor: pointer;

    & p {
      font-size: 16px;
    }

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: constants.$tablet) {
    align-items: flex-start;
  }
}

.socialLinks {
  display: flex;
  gap: 20px;
  margin: 0 0 30px;

  &__item {
    & p {
      font-size: 14px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: constants.$tablet) {
    margin: 0 0 5px;
  }
}

.note {
  max-width: 230px;
  text-align: right;
  color: #8f93a4;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: constants.$tablet) {
    text-align: left;
  }
}

.languageSelectorWrapper {
  align-self: flex-end;
  margin: 40px 0 0;

  @media (max-width: constants.$tablet) {
    align-self: flex-start;
    margin: 20px 0 0 -5px;
  }
}

.rightsRow {
  background-color: #101014;
  
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #787E83;
  }

  &__privacyPolicyLink {
    text-decoration: underline;
  }
}
