@use "../../styles/constants";

@mixin font-styles($element) {
  @if $element == 'point' {
    font-size: 18px;
    line-height: 25px;

    @media (max-width: constants.$tablet-l) {
      font-size: 16px;
    }
  }

  @if $element == 'point_marked' {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;

    @media (max-width: constants.$tablet-l) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: constants.$mobile) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.privacyPolicy {
  background-color: white;
}

.container {
  max-width: 720px;
}

.content {
  padding: 30px 0 70px;
}

.orderedList {
  counter-reset: section;
  list-style-type: none;
  margin: 0 0 32px;

  .orderedList {
    margin-top: 16px;
  }
}

.listItem {
  margin: 0 0 16px;

  &::before {
    counter-increment: section;
    content: counters(section, ".") ".";
    display: inline-block;
    margin: 0 10px 0 0;
    font-weight: 700;
    
    @include font-styles('point');
  }

  &Marked::before {
    @include font-styles('point_marked');
  }
}

.mainTitle {
  font-size: 32px;
  margin: 0 0 30px;

  @media (max-width: constants.$tablet-l) {
    font-size: 28px;    
  }
  @media (max-width: constants.$tablet) {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 20px;
  }
  @media (max-width: constants.$mobile) {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 15px;
  }
}

.point {
  margin: 0 0 16px;
  display: inline;

  @include font-styles('point');

  @media (max-width: constants.$tablet-l) {
    margin: 0 0 10px;
  }

  &Marked {
    @include font-styles('point_marked');
  }
}

.link {
  color: #FAA924;

  &:hover {
    opacity: 0.7;
  }
}
