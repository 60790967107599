@use "/src/styles/constants";

.card {
  width: calc(33.3% - 20px); // minus gap size
  max-width: calc(33.3% - 20px);
  min-height: 286px;
  display: block;
  border-radius: 32px;
  overflow: hidden;
  margin: 10px;
  padding: 25px;
  background-color: white;

  @media (max-width: constants.$laptop) {
    border-radius: 26px;
  }

  @media (max-width: constants.$tablet-l) {
    border-radius: 18px;
    padding: 24px;
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  @media (max-width: constants.$tablet) {
    border-radius: 24px;
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 130px;
  margin: 0 auto 20px;

  @media (max-width: constants.$mobile) {
    height: 100px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: inline-block;

  &::before,
  &::after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
  }

  &::after {
    content: "\2639";
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e6e6e6;
    z-index: 2;
    border: 1px solid #e6e6e6;
    border-radius: 9px;
  }
}

.title {
  font-weight: 700;
  margin: 0 0 7px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #afafaf;

  @media (max-width: constants.$mobile) {
    font-size: 12px;
    line-height: 16px;
  }
}
