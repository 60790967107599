@use "/src/styles/constants";

$mobile-width: constants.$mobile - 25px;

.wrapper {
  display: block;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  grid-column: auto;
  grid-row: auto;
  transform: translateZ(0);

  @media (max-width: constants.$tablet-l) {
    border-radius: 24px;
  }

  @media (max-width: constants.$tablet) {
    &:first-child {
      .content {
        padding: 0 0 calc(170% + 15px);
      }
    }
  }
}

.content {
  width: 100%;
  padding: 0 0 70%;
  position: relative;

  @media (max-width: constants.$tablet-l) {
    padding: 0 0 75%;
  }

  @media (max-width: constants.$tablet) {
    padding: 0 0 85%;
  }

  &_big {
    padding: 0 0 calc(140% + 20px);

    @media (max-width: constants.$tablet-l) {
      padding: 0 0  calc(150% + 20px);
    }

    @media (max-width: constants.$tablet) {
      padding: 0 0 85%;
    }
  }
}

.image {
  transition: transform 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper:hover {
  .image {
    transform: scale(1.2);
  }
}

.info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px 80px 24px 24px;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);

  @media (max-width: constants.$tablet-l) {
    padding: 16px 55px 16px 16px;
  }

  @media (max-width: constants.$tablet) {
    padding: 12px 55px 12px 12px;
  }

  @media (max-width: $mobile-width) {
    padding: 24px 80px 24px 24px;
  }
}

.galleryName {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #fff;

  @media (max-width: constants.$laptop) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: constants.$tablet-l) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: constants.$tablet) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: $mobile-width) {
    font-size: 22px;
    line-height: 26px;
  }
}

.artistName {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #afafaf;

  @media (max-width: constants.$tablet) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: $mobile-width) {
    font-size: 14px;
    line-height: 18px;
  }
}

.eyeWrapper {
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: white;
  opacity: 0.8;
  border-radius: 50%;

  & svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: constants.$tablet-l) {
    right: 16px;
    bottom: 16px;
    width: 40px;
    height: 40px;

    & svg {
      width: 22px;
      height: 22px;
    }
  }

  @media (max-width: constants.$tablet-l) {
    right: 12px;
    bottom: 12px;
    width: 36px;
    height: 36px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: $mobile-width) {
    right: 24px;
    bottom: 24px;
    width: 48px;
    height: 48px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }
}
