@use "../../styles/constants";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

.content {
  background-color: #1b1b1b;
  font-family: Manrope;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  font-size: 10vw;
  margin-top: 0;
  margin-bottom: 40px;
  opacity: 0;
  animation: fade-in 1s 0.3s ease forwards;

  @media (max-width: constants.$mobile) {
    font-size: 40px;
  }

  span {
    color: #4b4b4b;
  }
}

@keyframes drive {
  0% {
    left: 0;
  }

  30%{
    left: -10px;
  }

  60%
  {
    left: 15px;
  }

  90% {
    /* left: -5px; */
  }

  100% {
    left: 0;
  }
}

.link {
  font-size: 19px;
  background-color: white;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  left: 0;
  transition: left 0.3s ease;
  opacity: 0;
  animation: fade-in 1s 1.2s ease forwards, drive 1s 1.5s ease;
  height: 36px;

  @media (max-width: constants.$mobile) {
    font-size: 14px;
    height: 28px;
  }

  &:hover {
    left: 10px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    display: inline-block;
  }

  &::before {
    right: 100%;
    border: 18px solid white;
    border-left: 18px solid transparent;
    border-right: 0;

    @media (max-width: constants.$mobile) {
      border: 14px solid white;
      border-left: 14px solid transparent;
      border-right: 0;
    }
  }

  &::after {
    left: 100%;
    border: 18px solid transparent;
    border-left: 18px solid white;

    @media (max-width: constants.$mobile) {
      border: 14px solid transparent;
      border-left: 14px solid white;
    }
  }
}
