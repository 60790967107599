@use "/src/styles/constants";

.card {
  background-color: #f5f5f5;
  padding: 32px;
  border-radius: 32px;

  @media (max-width: constants.$mobile) {
    padding: 24px;
  }
}

.row {
  display: flex;
  align-items: stretch;

  &_info {
    @media (max-width: constants.$tablet-l) {
      flex-direction: column;
    }
  }

  @media (max-width: constants.$tablet-l) {
    justify-content: space-between;
  }

  @media (max-width: constants.$tablet) {
    flex-direction: column;
  }
}

.contactRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px;
  
  @media (max-width: constants.$mobile) {
    word-break: break-word; 
  }

  &:last-child {
    margin: 0;
  }
}

.info {
  flex: 1 1 auto;

  @media (max-width: constants.$tablet-l) {
    flex: 0 0 60%;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 68px 0 0;

  @media (max-width: constants.$laptop) {
    margin: 0 34px 0 0;
  }

  @media (max-width: constants.$tablet-l) {
    margin: 0 34px 30px 0;
  }

  @media (max-width: constants.$tablet) {
    margin: 0 0 30px;
  }
}

.title {
  text-align: left;
  margin: 0 0 30px;

  @media (max-width: constants.$tablet) {
    margin: 0 0 10px;
  }
}

.social {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 68px 0 0;

  &__links {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;

    @media (max-width: constants.$tablet-l) {
      margin: 0 0 20px;
    }
  }

  @media (max-width: constants.$laptop) {
    margin: 0 34px 0 0;
  }

  @media (max-width: constants.$tablet) {
    margin: 0 0 30px;
  }
}

.subtitle {
  font-weight: 700;
  margin: 20px 0 35px;

  @media (max-width: constants.$tablet-l) {
    margin: 0 0 20px;
  }
}

.note {
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
  color: #787e83;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 17.5px;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin: 0 15px 0 0;
  position: relative;
  transition: opacity 0.15s ease;

  &Banned {
    &::after {
      content: "*";
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }

  @media (max-width: constants.$tablet) {
    width: 48px;
    min-width: 48px;
    height: 48px;

    svg {
      transform: scale(0.8);
    }
  }
}

a.iconWrapper:hover {
  opacity: 0.6;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &Wrapper {
    flex: 0 1 416px;
    max-width: 50%;
    border-radius: 32px;
    overflow: hidden;
    align-self: center;

    @media (max-width: constants.$tablet) {
      flex: none;
      width: 100%;
      max-width: 100%;
    }
  }

  &Container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
}

.button.button {
  min-width: 333px;
  margin: 0;

  @media (max-width: constants.$laptop) {
    &.button {
      min-width: 290px;
    }
  }

  @media (max-width: constants.$tablet-l) {
    &.button {
      min-width: 250px;
      width: 100%;
    }
  }

  @media (max-width: constants.$tablet) {
    &.button {
      min-width: 200px;
    }
  }
}
