.sidebar {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100vh;
  width: 88px;
  padding: 90px 20px 20px;
  background-color: black;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  display: none; // side bar shouldn't be displayed for a while
}

.wrapper {
  padding: 90px 0 0;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 100%;
}
