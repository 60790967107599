@use "../../styles/constants";

.section {
  background-color: black;
  color: white;
}

.title.title {
  color: white;
  text-transform: uppercase;
  text-align: left;
  width: 530px;
  max-width: 100%;

  span {
    display: inline-block;
    border-radius: 32px;
    padding: 0 5px;
    background-color: #80f1ae;
    color: black;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: constants.$tablet) {
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin: 70px 0 0 30px;
  width: 630px;
  max-width: 100%;

  @media (max-width: constants.$tablet) {
    margin: 30px 0 0;
  }
}
