@use "/src/styles/constants";

.button {
  padding: 20px;
  background-color: black;
  border-radius: 16px;
  color: white;
  width: calc(33.3% - 20px);
  min-width: 200px;
  max-width: 100%;
  margin: 10px auto 0;
  display: block;
  text-align: center;
  font-size: 18px;

  @media (max-width: constants.$tablet-l) {
    padding: 18px;
    font-size: 16px;
  }
}
