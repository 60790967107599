@font-face {
  font-family: Manrope;
  font-weight: 400;
  src: url(./fonts/Manrope-Regular.eot);
  src:
    url(./fonts/manrope-regular-cyrillic.woff2) format("woff2"),
    url(./fonts/manrope-regular-cyrillic.woff) format("woff"),
    url(./fonts/manrope-regular-cyrillic.ttf) format("truetype");
}

@font-face {
  font-family: Manrope;
  font-weight: 600;
  src: url(./fonts/manrope-semibold-cyrillic.eot);
  src:
    url(./fonts/manrope-semibold-cyrillic.eot?#iefix) format("embedded-opentype"),
    url(./fonts/manrope-semibold-cyrillic.woff2) format("woff2"),
    url(./fonts/manrope-semibold-cyrillic.woff) format("woff"),
    url(./fonts/manrope-semibold-cyrillic.ttf) format("truetype");
}

@font-face {
  font-family: Manrope;
  font-weight: 700;
  src: url(./fonts/manrope-bold-cyrillic.eot);
  src:
    url(./fonts/manrope-bold-cyrillic.eot?#iefix) format("embedded-opentype"),
    url(./fonts/manrope-bold-cyrillic.woff2) format("woff2"),
    url(./fonts/manrope-bold-cyrillic.woff) format("woff"),
    url(./fonts/manrope-bold-cyrillic.ttf) format("truetype");
}
