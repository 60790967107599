@use "/src/styles/constants";

.burgerButton {
  width: 25px;
  height: 25px;
  background-color: transparent;
  display: none;
  position: relative;
  z-index: 13;

  @media (max-width: constants.$tablet) {
    display: initial;
  }
}

.burger {
  position: relative;

  & span,
  &::before,
  &::after {
    display: block;
    width: 25px;
    height: 2px;
    background-color: white;
    transition: transform ease 0.3s, background-color ease 0.3s;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
  }

  &::before {
    top: -8px;
  }

  &::after {
    bottom: -8px;
  }

  &_open {
    & span,
    &::before,
    &::after {
      background-color: black;
    }

    & span {
      transform: scale(0, 0);
    }

    &::before {
      transform: translateY(8px) rotate(45deg);
    }

    &::after {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
