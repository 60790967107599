.languageSection {
  display: flex;
  align-items: center;
}

.languageButton {
  min-height: 20px;
  border: none;
  background-color: transparent;
  color: #8f93a4;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: 5px;

  &:hover {
    opacity: 0.6;
  }

  &_selected {
    color: inherit;
  }
}

.divider {
  display: inline-block;
  height: 20px;
  border: 1px solid #8f93a4;
}
