@use "/src/styles/constants";

.section {
  padding: 105px 0;

  @media (max-width: constants.$tablet-l) {
    padding: 60px 0;
  }

  @media (max-width: constants.$tablet-l) {
    padding: 30px 0;
  }
}

.title {
  margin: 0 0 30px;

  @media (max-width: constants.$tablet-l) {
    margin: 0 0 10px;
  }
}

.galleriesList {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: constants.$tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: calc(constants.$mobile - 25px)) {
    grid-template-columns: 1fr;
  }
}

.bigGalleryCard {
  grid-row: 1 / span 2;
  
  @media (max-width: constants.$tablet) {
    grid-row: auto;

    &:first-child {
      grid-row: 1 / span 2;
    }
  }
}

.button {
  margin: 20px auto 0;
}
