@use "/src/styles/constants";

.h1 {
  text-align: center;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;

  @media (max-width: constants.$laptop) {
    font-size: 36px;
    line-height: 44px;
  }

  @media (max-width: calc(constants.$mobile - 150px)) {
    font-size: 30px;
    line-height: 38px;
  }
}

.h2 {
  text-align: center;
  font-weight: 700;
  font-size: 34px;
  line-height: 49px;
  margin: 0 0 40px;

  @media (max-width: constants.$laptop) {
    font-size: 28px;
    line-height: 40px;
  }
}

.p {
  margin: 0 0 30px;
  font-size: 18px;
  line-height: 25px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: constants.$laptop) {
    margin: 0 0 20px;
  }
}
