@use "/src/styles/constants";

.opportunitiesList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  position: relative;

  @media (max-width: constants.$tablet) {
    margin: 0 -5px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: calc(constants.$tablet)) {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 36px;    
  }

  @media (max-width: calc(constants.$mobile - 30px)) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: calc(constants.$mobile - 80px)) {
    font-size: 22px;
  }

  @media (max-width: calc(constants.$mobile - 120px)) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: calc(constants.$mobile - 150px)) {
    font-size: 18px;
    line-height: 26px;
  }
}
