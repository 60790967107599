@use "/src/styles/constants";

.header {
  background-color: black;
  color: white;
  height: 90px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 10px 0;
}

.brand.brand {
  @media (max-width: constants.$tablet-l) {
    font-size: 26px;
  }
}

.menu {
  display: flex;
  align-items: center;
  list-style: none;
  position: absolute;
  right: 50%;
  transform: translateX(50%);

  @media (max-width: constants.$tablet) {
    position: absolute;
    left: 100%;
    right: unset;
    transform: unset;
    top: 0;
    background-color: white;
    color: black;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    transition: left 0.3s ease;
    flex-direction: column;
    padding: 110px 30px 30px;
    overflow-y: auto;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 90px;
      top: 0;
      left: 100%;
      z-index: 2;
      background-color: white;
      transition: left 0.3s ease;
      position: fixed;
    }

    &_open {
      left: 0%;

      &::before {
        left: 0%;
      }
    }
  }

  &__item {
    font-size: 16px;
    line-height: 22px;
    margin: 0 20px 0 0;
    cursor: pointer;
    transition: color 0.05s ease;

    &:hover {
      color: #8f93a4;
    }

    &:last-child {
      margin: 0;
    }

    @media (max-width: constants.$tablet) {
      padding: 10px 0;
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

div.languageSelector {
  &_desktop {
    @media (max-width: constants.$tablet) {
      display: none;
    }
  }

  &_mobile {
    display: none;

    @media (max-width: constants.$tablet) {
      display: initial;
    }
  }
}
